.product {
  margin: 10px 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 290px;
  width: 188px;
  padding: 8px 0px 8px 8px;
}

.product:hover {
  -webkit-box-shadow: 2px 2px 4px 4px rgba(214, 214, 214, 1);
  -moz-box-shadow: 2px 2px 4px 4px rgba(214, 214, 214, 1);
  box-shadow: 2px 2px 4px 4px rgba(214, 214, 214, 1);
}

.product__image {
  width: 170px;
  height: 170px;
  object-fit: cover;
}

.product__price {
  color: #f57224;
  font-size: 18px;
}

.product__rating {
  display: flex;
}
.strike {
  padding: 0;
  margin: 0;
  text-decoration-line: line-through;
}

.star {
  color: rgb(252, 168, 43);
  font-size: 14px !important;
}

.graystar {
  color: rgb(201, 201, 201);
  font-size: 14px !important;
}

.product__title {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
