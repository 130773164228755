.footer {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  color: #fff;
  padding-top: 25px;
  padding-bottom: 25px;
}

.footer-container {
  background: #2e2e54;
}

li {
  text-decoration: none;
  list-style: none;
}

.footer__col3top {
  display: flex;
  margin-bottom: 10px;
}
.footer__col1 {
  flex: 1;
}
.footer__col2 {
  flex: 1;
}
.footer__col3 {
  display: flex;
  flex: 2;
  flex-direction: column;
}

.smallfont {
  font-size: 12px;
  line-height: 18px;
}

p {
  margin-bottom: 10px;
}

.qrcode {
  width: 100px;
  border-radius: 10px;
}

.smalldarazlogo {
  width: 42px;
  height: 42px;
}

.footer__col3left {
  margin-right: 10px;
}

.col1__top {
  margin-bottom: 10px;
}

.footer__text__orange {
  color: orange;
}
.footer__text__white {
  color: white;
}

h3 {
  margin-bottom: 8px;
  font-weight: 400;
}
h4 {
  font-size: 12px;
}

.footer__secondary {
  display: flex;

  color: #888888;
  margin: 20px 48px;
  justify-content: space-between;
}

.footer__end {
  background: #f1f1f1;
  padding-top: 20px;
}

.footer__secondary__col {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
}

.footer-sprit {
  background-image: url(//laz-img-cdn.alicdn.com/tfs/TB1iD3fNbvpK1RjSZFqXXcXUVXa-317-281.png);
  display: inline-block;
}

.icon-appstore {
  background-position: 0 -110px;
  width: 120px;
  height: 40px;
  margin-right: 5px;
}

.icon-googleplay {
  background-position: 0 -65px;
  width: 134px;
  height: 40px;
}

.icon-payment1 {
  background-position: -161px 0;
  width: 55px;
  height: 40px;
}
.icon-payment2 {
  background-position: -60px -200px;
  width: 55px;
  height: 40px;
}

.icon-payment3 {
  background-position: -120px -200px;
  width: 55px;
  height: 40px;
}

.icon-pk {
  background-position: -164px -245px;
  width: 36px;
  height: 36px;
}

.icon-bd {
  background-position: 0 -245px;
  width: 36px;
  height: 36px;
}

.icon-sl {
  background-position: -41px -245px;
  width: 36px;
  height: 36px;
}

.icon-mm {
  background-position: -82px -245px;
  width: 36px;
  height: 36px;
}

.icon-np {
  background-position: -123px -245px;
  width: 36px;
  height: 36px;
}

.icon-fb {
  background-position: -281px -164px;
  width: 36px;
  height: 36px;
}
.icon-in {
  background-position: -281px -41px;
  width: 36px;
  height: 36px;
}
.icon-yt {
  background-position: -180px -155px;
  width: 36px;
  height: 36px;
}
.icon-dblog {
  background-position: -240px -200px;
  width: 36px;
  height: 36px;
}
.icon-pci {
  background-position: 0 0;
  width: 156px;
  height: 60px;
}
.flex {
  display: flex;
}

.footer__middle {
  display: flex;
  padding-top: 20px;
  width: 95%;
}

.col1,
.col2,
.col3,
.col4 {
  width: 25%;
}

.footer__title {
  margin: 0 0 16px;
  font-size: 18px;
  font-weight: 400;
}

.footerlinks {
  color: #fff;
}
.footerlinks:hover {
  color: rgb(172, 172, 172);
}
