.login {
  background: #f5f5f5;
}

.login__form > form {
  background: #fff;
  margin: 16px auto;
  width: 30%;
  padding: 16px;
}

.emailLogin {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #ddd;
  font-size: 14px;
  height: 40px;
  line-height: 38px \0;
  padding: 0 35px 0 10px;
  margin-bottom: 2px;
  outline: none;
}

form > h2 {
  margin-bottom: 20px;
  font-weight: 400;
}

.loginBtnForm {
  border-style: solid;
  background-color: #f57224;
  border-color: transparent;
  margin: 0;
  height: 48px;
  color: #fff;
  padding: 0 36px;
  font-size: 14px;
  line-height: 46px;
  border-width: 1px;
}

.loginBtnForm:hover {
  background-color: #ca5c1b;
}

.loginBtnReg {
  background-color: #07a8cc;
}
.loginBtnReg:hover {
  background-color: #0e88a3;
}

.formPad {
  margin: 20px 0;
}
.registerSection {
  display: flex;
}

.cleanBtn {
  border: none;
  background: none;
  font-size: 16px;
  float: right;
  font-weight: 400;
}

.cleanBtn:hover {
  color: rgb(123, 123, 123);
}

.cleanBtnblue {
  border: none;
  background: none;
  font-size: 16px;
  float: right;
  color: blue !important;
}

.cleanBtnblue:hover {
  color: rgb(68, 68, 235);
}

.img-center {
  margin: 0 auto;
}
